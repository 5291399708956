"use client";
import Logo from "@/assets/images/logo-pax-sapiens.svg";
import Button from "@/components/Button/Button";
import Image from "next/image";
import { useEffect } from "react";
import styles from "./global-error.module.scss";

// Separate error log object for global errors
const globalErrorLog = {
  lastErrorKey: "",
  lastErrorTime: 0,
  DEDUPE_WINDOW: 5000,
};

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    const logError = async () => {
      try {
        const errorKey = `${error.digest}-${error.message}`;
        const now = Date.now();

        if (
          errorKey === globalErrorLog.lastErrorKey &&
          now - globalErrorLog.lastErrorTime < globalErrorLog.DEDUPE_WINDOW
        ) {
          return;
        }

        globalErrorLog.lastErrorKey = errorKey;
        globalErrorLog.lastErrorTime = now;

        const response = await fetch("/api/log-error", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            digest: error.digest,
            stack: error.stack,
            message: error.message,
            url: window.location.pathname,
            isGlobal: true,
            timestamp: new Date().toISOString(),
          }),
        });

        if (!response.ok) {
          console.error(
            "[LOGGING]: Failed to log error:",
            await response.text()
          );
        }
      } catch (e) {
        console.error("[LOGGING]: Error while logging error:", e);
      }
    };

    logError();
  }, [error]);

  return (
    <html>
      <body>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <Image src={Logo} width={300} alt="ONN Logo" />
          </div>
          <h1 className={styles.headline}>An Error occured</h1>
          <p>We encountered an unexpected Error. This error has been logged.</p>
          <div className="flex flex-row gap-2">
            <Button cta={true} type="primary" href="/">
              Return Home
            </Button>{" "}
            <Button type="secondary" onClick={() => window.location.reload()}>
              Try again
            </Button>
          </div>
        </div>
      </body>
    </html>
  );
}
